import PropTypes from 'prop-types';
import { useState } from "react";
import { Button, Box, Typography, Chip, Divider, IconButton, Menu, MenuItem, } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CountdownTimer from './CountdownTimer';

export default function AttendantCard({ name, online, open, inDutyTime, zoom, countdownRemainingSeconds, onOpenClick, onStopCountdown, onShowCountdown, onZoomClick }) {
  const [anchorEl, setAnchorEl] = useState();

  const chip = (key, label, color, filled = false) => {
    return (
      <Chip
        key={key}
        sx={{
          width: 60
        }}
        label={label}
        size='small'
        color={color}
        variant={filled ? 'filled' : 'outlined'}
      />)
  }

  const countdown = () => {
    return (
      <Chip
        sx={{
          width: 90
        }}
        label={<CountdownTimer initialSeconds={countdownRemainingSeconds} />}
        variant='outlined'
        onDelete={onStopCountdown}
      />
    )
  }

  const offlineAlarm = open && !online;
  const states = () => {
    const dutyLabel = 'Duty';
    const onlineLabel = 'Online';
    const offlineLabel = 'Offline';
    const onColor = 'success';
    const offColor = 'default'
    const alarmColor = 'error'
    const onlineColor = offlineAlarm
      ? alarmColor
      : (online ? onColor : offColor);

    const dutyColor = inDutyTime ? (online ? onColor : alarmColor) : offColor
    const zoomColor = zoom ? onColor : (inDutyTime ? alarmColor : offColor);
    return [
      chip(2, online ? onlineLabel : offlineLabel, onlineColor),
      chip(3, 'Zoom', zoomColor, zoomColor === alarmColor),
      chip(4, dutyLabel, dutyColor, dutyColor === alarmColor)
    ];
  }

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem dense divider>
          <Button
            color={open ? "error" : "success"}
            size="small"
            disabled={!online && !open}
            onClick={() => {
              setAnchorEl(null)
              onOpenClick()
            }}
          >
            {open ? "Off" : "On"}
          </Button>
        </MenuItem>
        <MenuItem dense divider>
          <Button
            size="small"
            onClick={() => {
              setAnchorEl(null)
              onShowCountdown()
            }}
          >CR</Button>
        </MenuItem>
        <MenuItem dense>
          <Button
            color={zoom ? 'error' : 'success'}
            size="small"
            onClick={() => {
              setAnchorEl(null)
              onZoomClick()
            }}
          >Zoom</Button>
        </MenuItem>
      </Menu>

      <Box
        sx={{
          minWidth: 270,
          maxWidth: 330,
          height: 100,
          border: open ? '2px solid #42f545' : '1px solid #cc',
          borderRadius: 3,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: open ? '0 0 10px #42f545' : '',
          cursor: 'pointer', // 添加指针样式以指示可点击
          transition: 'background-color 0.3s ease', // 添加过渡效果
          '&:hover': {
            backgroundColor: 'lightskyblue', // 悬停时的背景色
          },
          backgroundColor: offlineAlarm ? 'pink' : 'whitesmoke'
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >

          <Typography fontWeight="bold" >{name}</Typography>
          {countdownRemainingSeconds != null ? countdown() : ''}
          <IconButton size="small" onClick={(event) => setAnchorEl(event.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </Box>

        <Divider />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {states()}
        </Box>
      </Box >
    </>
  );
}
// chip(1, countdown(), 'success', false, onStopCountdown, 70)
AttendantCard.propTypes = {
  name: PropTypes.string,
  online: PropTypes.bool,
  open: PropTypes.bool,
  inDutyTime: PropTypes.bool,
  zoom: PropTypes.bool,
  countdownRemainingSeconds: PropTypes.number,
  onOpenClick: PropTypes.func,
  onStopCountdown: PropTypes.func,
  onZoomClick: PropTypes.func,
  onShowCountdown: PropTypes.func
};