import { request, toForm } from "./request";
import { serverURL, loginPath } from '../config';

let mToken;
let mRefreshToken
export function getToken() {
  if (mToken) {
    return mToken
  }
  mToken = localStorage.getItem('token')
  return mToken
}

function setToken(token) {
  localStorage.setItem('token', token);
  mToken = token;
}
function getRefreshToken() {
  if (mRefreshToken) {
    return mRefreshToken
  }
  mRefreshToken = localStorage.getItem('refreshToken')
  return mRefreshToken
}
function setRefreshToken(token) {
  mRefreshToken = token
  localStorage.setItem('refreshToken', token)
}

let isRefreshing = false;
let refreshSubscribers = [];
const onRrefreshed = (token) => {
  refreshSubscribers.map((callback) => callback(token));
};
const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

function saveToken(token, refreshToken) {
  setToken(token);
  setRefreshToken(refreshToken);
}

function backToLogin() {
  if (window.location.href.indexOf(loginPath) === -1) {
    window.location.href = loginPath;
  }
}

async function refreshToken() {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    backToLogin();
    return null;
  }

  return fetch(`${serverURL}/auth/refreshToken`, {
    method: 'POST',
    body: toForm({ refreshToken }),
  }).catch((err) => {
    backToLogin();
  });;
}

// 请求失败后重新刷新token，然后再次恢复请求
export async function onAuthFailed(url, method, body) {
  if (!isRefreshing) {
    // token过期，需要auth更换token
    isRefreshing = true;
    const refreshResponse = await refreshToken();
    if (refreshResponse && refreshResponse.ok) {
      const data = await refreshResponse.json();
      saveToken(data.result.token, data.result.refreshToken);
      isRefreshing = false;
      onRrefreshed(data.result.token);
      refreshSubscribers = [];
    } else {
      isRefreshing = false;
      refreshSubscribers = [];
      backToLogin();
    }
  }

  // 注册重新调用请求
  const retryOriginalRequest = new Promise((resolve) => {
    addRefreshSubscriber((newToken) => {
      resolve(request(url, method, body, true));
    });
  });
  return retryOriginalRequest;
}

// 登录
export async function signin(username, password, googleAuthCode) {
  const body = toForm({ username, password, googleAuthCode });
  const data = await fetch(`${serverURL}/auth/signin`, {
    method: 'post',
    body
  }).then((response) => response.json());
  if (data.success) {
    saveToken(data.result.token, data.result.refreshToken);
  }
  return data;
}

// 登出
export function signout() {
  setToken(null);
  setRefreshToken(null);
}
