import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

export default function CountdownTimer({ initialSeconds }) {
  const [timeLeft, setTimeLeft] = useState(initialSeconds);

  useEffect(() => {
    setTimeLeft(initialSeconds)
    if (initialSeconds <= 1) {
      setTimeLeft(0);
      return () => { };
    };

    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => {
        const next = prevTime - 1;
        return next >= 0 ? next : prevTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [initialSeconds]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Typography
      sx={{
        width: 70,
        color: timeLeft <= 0 ? 'red' : 'black'
      }}
      fontWeight='bold'
      fontSize={17}
    >
      {formatTime(timeLeft)}
    </Typography>
  );
};

CountdownTimer.propTypes = {
  initialSeconds: PropTypes.number,
};