import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from "dayjs";

export function dayStart() {
  return dayjs().startOf('day').format("YYYY-MM-DD HH:mm:ss");
}

export function dayEnd() {
  return dayjs().endOf('day').format("YYYY-MM-DD HH:mm:ss");
}

export function datetime(value) {
  return dayjs(value).format('YY-MM-DD HH:mm:ss')
}


export function fDate(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}
