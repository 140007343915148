import { Box } from '@mui/material';

export function NavWimdow({ children }) {
  return (<Box
    sx={{
      height: 'calc(100vh - 70px)',  // 填满整个视口高度
      display: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      overflow: 'auto',  // 防止外层容器出现滚动条
    }}>
    {children}
  </Box>)
}