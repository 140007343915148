import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { Tooltip, Stack, Box, Dialog, DialogContent, DialogTitle, DialogActions, Button, TextField } from '@mui/material';
import { useSnackbar } from "standard";
import { fDate } from '../../utils/formatTime';

export default function ScheduleForm({ open, id, name, date, startAt, hours, onSubmit, onDelete, onClose }) {
  const { setSnackbar } = useSnackbar();
  const [days, setDays] = useState(1)
  const [startAtValue, setStartAt] = useState(startAt ?? 9)
  const [hoursValue, setHours] = useState(hours ?? 9)
  const [endDate, setEndDate] = useState(date)

  useEffect(() => {
    setDays(1)
    setEndDate(date)
  }, [id, date, startAt, hours])

  const handleSubmit = () => {
    if (!validateDays()) {
      return
    }
    if (!startAtValue) {
      setSnackbar('Please input【Work Start Time】', 'error')
      return
    }
    if (!hoursValue) {
      setSnackbar('Please input【Work Duration】', 'error')
      return
    }
    onSubmit(id, fDate(date), days, startAtValue, hoursValue)
    onClose()
  }

  const handleDelete = () => {
    if (!validateDays()) {
      return
    }
    onDelete(id, fDate(date), days)
    onClose()
  }

  const validateDays = () => {
    if (!days) {
      setSnackbar('Please input【Days】', 'error')
      return false
    }
    if (parseInt(days, 10) > 100) {
      setSnackbar('【Days】can not be more than 100', 'error')
      return false
    }

    return true
  }

  const handleDays = (v) => {
    setDays(v)
    if (v && parseInt(v, 10) > 0) {
      const result = new Date(date)
      result.setDate(result.getDate() + parseInt(v, 10) - 1)
      setEndDate(result)
    } else {
      setEndDate(date)
    }
  }

  const input = (label, value, onChange) => {
    return <TextField
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          fontWeight: 'bold',
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)', // 保持禁用状态下的颜色
        },
      }}
      type={onChange ? 'number' : 'text'}
      label={label}
      variant="outlined"
      value={value}
      margin="dense"
      disabled={!Boolean(onChange)}
      onChange={(event) => onChange(event.target.value)}
    />
  }
  return (
    <Dialog
      open={Boolean(open)}
      onClose={onClose}
    >
      <DialogTitle align='center'>
        Work Schedule
      </DialogTitle>
      <DialogContent
        sx={{
          width: 500
        }}
      >
        <Stack>
          {input("Name", name)}
          <Box>
            {input("Start Date", fDate(date))} {input("End Date", fDate(endDate))}
          </Box>

          {input("Days", days, handleDays)}
          {input("Work Start Time (24 Hours)", startAtValue, v => setStartAt(v))}
          {input("Work Duration (Hours)", hoursValue, v => setHours(v))}
        </Stack>

      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center' }}
      >
        {startAt &&
          <Tooltip title="Delete Work Schedule">
            <Button color='error' onClick={handleDelete}>Delete</Button>
          </Tooltip>}


        <Tooltip title="Submit Work Schedule">
          <Button onClick={handleSubmit}>Submit</Button>
        </Tooltip>
      </DialogActions>
    </Dialog >
  )
}
ScheduleForm.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.object,
  startAt: PropTypes.number,
  hours: PropTypes.number,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func
};