import { useState, useEffect } from "react";
import { Box, Typography, FormControlLabel, Divider, Drawer, Stack, Switch } from '@mui/material';
import { StandardSearchGroup, StandardTable } from "standard";
import API from "../../utils/api";
import InputSelect from "../InputSelect";
import { datetime } from "../../utils/formatTime";
import AttendantCard from "./AttendantCard";

const TEST_DATA = () => {

  const one = Array(10).fill().map((_, index) => ({
    id: `OTC ${index}`,
    name: `Item ${index + 1}`,
    teamName: 'OTC', online: index % 2 === 0, open: false, relax: false, zoom: false
  }));
  one.push({
    id: `OTC11`,
    name: `Item11`,
    teamName: 'OTC', online: false, open: false, relax: false, zoom: false,
    countdownRemainingSeconds: 0,
  })
  one.push({
    id: `OTC12`,
    name: `Item12`,
    teamName: 'OTC', online: false, open: false, relax: false, zoom: false,
    countdownRemainingSeconds: 10,
  })
  const two = Array(10).fill().map((_, index) => ({
    id: `lelepay ${index}`,
    name: `Item ${index + 1}`,
    teamName: 'lelepay', online: index % 2 === 0, open: false, relax: false, zoom: false
  }));
  return [...one, ...two];
}

export default function Workspace() {
  const [queryParams, setQueryParams] = useState({
    attendantId: null,
  });
  const [rows, setRows] = useState([]);
  const [inputName, setInputName] = useState();
  const [attendants, setAttendants] = useState([]);
  const [summary, setSummary] = useState({
    total: 0,
    open: 0
  });
  const [countdowns, setCountdowns] = useState();
  const [cr, setCR] = useState(true)

  useEffect(() => {
    // 初始化所有数据
    queryAttendant();
    queryCR();
    async function func() {
      const info = await API.wsAllAttendants(queryParams);
      info.result.sort((a, b) => b.name.localeCompare(a.name));
      setAttendants(info.result);
    }
    func();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(queryAttendant, 5000);
    return () => clearInterval(intervalId);
  }, [queryParams]);

  useEffect(() => {
    const intervalId = setInterval(queryCR, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const queryCR = async () => {
    API.wsCR(v => setCR(v));
  }

  const queryAttendant = async () => {
    const info = await API.wsAttendants(queryParams);


    // info.result = TEST_DATA();


    setRows(info.result);
    setSummary({
      total: info.result.length,
      open: info.result.filter(v => v.open).length
    })
  }

  const handleAttendantOpen = (id, active) => {
    async function func() {
      const info = await API.wsAttendantOpen(id, active);
      replaceRow(info.result)
    }
    func();
  }

  const handleZoomClick = (id, connected) => {
    async function func() {
      const info = await API.wsAttendantZoom(id, connected);
      replaceRow(info.result)
    }
    func();
  }

  const handleStopCountdown = (id) => {
    async function func() {
      const info = await API.wsStopCountdown(id);
      replaceRow(info.result)
    }
    func();
  };

  const handleShowCountdowns = (id) => {
    async function func() {
      const info = await API.wsCountdowns(id);
      setCountdowns(info.result.content)
    }
    func();
  };

  const replaceRow = (row, resort = false) => {
    const index = rows.findIndex(v => v.id === row.id);
    if (index >= 0) {
      const newRows = [...rows]
      newRows[index] = row;
      setRows(newRows);
    }
  }

  const handleCR = (event) => {
    async function func() {
      await API.wsSetCR(event.target.checked, v => setCR(v));
    }
    func();
  };


  const footer = () => {
    return (
      <Box sx={{
        borderTop: `1px solid rgba(128, 128, 128, 0.1)`,
        width: '100%',
        padding: 0.5,
        display: "flex",
        direction: 'row',
        justifyContent: "space-around",
      }}>
        <Typography color={'error'}>Total: {summary.total}</Typography>
        <Typography color={'error'}>Open: {summary.open}</Typography>
      </Box>
    )
  }

  const searchBar = () => {
    return (
      <div>
        <StandardSearchGroup
          options={[
            {
              type: 'component', name: 'attendants', width: 150, render: () => {
                return (
                  <InputSelect
                    options={attendants.map(v => { return { label: v.name, value: v.id } })}
                    label="Name"
                    onChange={attendantId => {
                      setQueryParams({ ...queryParams, attendantId });
                      if (!attendantId) {
                        setInputName(null);
                      }
                    }}
                    onInputChange={setInputName}
                  />
                )
              }
            },
          ]}
          queryButtonLabel='Search'
          setVersion={() => { queryAttendant() }}
          setQueryParams={(v) => {
            setQueryParams({ ...queryParams, ...v })
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={cr}
                onChange={handleCR}
              />
            }
            label="CR"
          />


        </StandardSearchGroup>
        <Divider />
      </div>
    )
  }

  const countdownRecordsDrawer = () => {
    return (
      <Drawer
        anchor='right'
        open={Boolean(countdowns)}
        onClose={() => setCountdowns(null)}
      >
        <StandardTable
          columns={[
            {
              headerName: '开启时间', field: 'startTime', width: 160,
              renderCell: (params) => datetime(params.row.startTime)
            },
            { headerName: '时长', field: 'duration', },
            {
              headerName: '结束时间', field: 'endTime', width: 160,
              renderCell: (params) => params.row.endTime && datetime(params.row.endTime)
            },
          ]}
          query={() => { }}
          data={countdowns}
          version={0}
          notFoundSrc={`no_items_found.png`}
          rowHeight={40}
        />
      </Drawer>
    )
  }

  const sortRows = (rows) => {
    const sortByCountdown = (a, b) => {
      const isNumber = (v) => typeof v === 'number'
      if (isNumber(b.countdownRemainingSeconds) && isNumber(b.countdownRemainingSeconds)) {
        return b.countdownRemainingSeconds - a.countdownRemainingSeconds;
      }
      if (isNumber(a.countdownRemainingSeconds)) {
        return -1; // a 应该排在 b 前面
      }
      if (isNumber(b.countdownRemainingSeconds)) {
        return 1; // b 应该排在 a 前面
      }
      return null; // 保持原有顺序
    }

    rows.sort((a, b) => {
      const com = sortByCountdown(a, b);
      // return com;
      if (com != null) {
        return com;
      }

      if (a.open !== b.open) {
        return b.open ? 1 : -1;
      }

      // relax 为 true 的排在前面
      // if (a.relax !== b.relax) {
      //   return b.relax ? 1 : -1;
      // }

      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    })

    return rows;
  }

  const teamOrder = ['OTC', 'lelepay', 'TestProvider', 'alipay'];
  const plateGroups = () => {
    const focusPredicate = (item) => {
      return item.inWorkingTime || item.open || item.countdownRemainingSeconds != null
    }
    const focus = rows.filter(v => focusPredicate(v));
    const teams = Object.groupBy(focus, ({ teamName }) => teamName);
    const result = Object.values(teamOrder)
      .map(v => teams[v])
      .filter(v => v)
      .map(v => { return { title: v[0].teamName, items: sortRows(v) } });

    const others = rows.filter(v => !focusPredicate(v));
    if (others.length > 0) {
      result.push({ title: 'Others', items: sortRows(others) });
    }
    return result.map(v => plate(v.title, v.items));
  }

  const plate = (title, items) => {
    return (
      <Box key={title}>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: 100 }} />
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <Box sx={{ width: 100 }}>
            Count: {items.length}
          </Box>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 1,
            padding: 1,
            overflowY: 'auto',  // 允许垂直滚动
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
          }}
        >
          {items
            .filter(v => !inputName || v.name.toLowerCase().includes(inputName.toLowerCase()))
            .map(v =>
              <AttendantCard
                key={v.id}
                id={v.id}
                name={v.name}
                online={v.online}
                open={v.open}
                inDutyTime={v.inWorkingTime}
                zoom={v.zoom}
                onOpenClick={() => handleAttendantOpen(v.id, !v.open)}
                onZoomClick={() => handleZoomClick(v.id, !v.zoom)}
                countdownRemainingSeconds={v.countdownRemainingSeconds}
                onStopCountdown={() => handleStopCountdown(v.id)}
                onShowCountdown={() => handleShowCountdowns(v.id)}
              />)}
          <Divider sx={{ width: '100%' }} />
        </Box>
      </Box>
    )
  }

  return (
    <div>
      {searchBar()}
      <Box
        sx={{
          height: 'calc(100vh - 160px)',  // 填满整个视口高度
          display: 'flex-start',
          flexDirection: 'column',
          width: '100%',
          overflow: 'auto',  // 防止外层容器出现滚动条
        }}>
        {plateGroups()}
      </Box>
      {footer()}
      {countdownRecordsDrawer()}
    </div>

  )
}