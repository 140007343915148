import { useState } from "react";
import { Button, Popover, Tooltip, Box, ToggleButton } from "@mui/material";
import dayjs from "dayjs";
import { StandardSearchGroup, StandardTable, useSnackbar, Inspector, useStore } from "standard"
import TimeElapsed from "../withdraw/TimeElapsed";
import API from "../../utils/api";
import CopyableText from '../withdraw/CopyableText';
import OpMenu from "./OpMenu";
import MutiSelect from "./MutiSelect";

export default function PortionPage() {
  const { setSnackbar } = useSnackbar();
  const [queryParams, setQueryParams] = useState({
    timeStart: null,
    timeEnd: null,
    pid: null,
    trxId: null,
    onlyMine: true,
    uncompleted: true,
    statuses: [],
  });
  const [version, setVersion] = useState(0);
  const [details, setDetails] = useState(null);
  const { store } = useStore();

  const handleOpenDetails = (portionId, spId) => {
    async function func() {
      const split = await API.spInfo(spId);
      const portion = await API.spPortionInfo(portionId);
      setDetails({ split: split.result, portion: portion.result })
    }
    func()
  }

  const query = async (params) => {
    params = { ...params }
    if (params.onlyMine) {
      // TODO 刷新页面后，store 还没有完成加载，onlyMine 没有起作用
      params.creater = store.name
    }
    params.onlyMine = null
    const info = await API.spPortionsPage(params);
    return {
      list: info.result.content,
      pagination: {
        total: info.result.totalElements
      }
    };
  }

  const idWithDetailsButton = (row) => {
    return (
      <Tooltip title="查看分单详情">
        <Button
          size="small"
          onClick={() => handleOpenDetails(row.id, row.pid)}
        >
          {row.id}
        </Button>
      </Tooltip>
    );
  }
  const anchorPosition = { top: 60, left: window.innerWidth / 2 };

  const copied = (text) => {
    setSnackbar('已复制', 'success');
    navigator.clipboard.writeText(text);
  }

  return (
    <>
      <Popover
        open={Boolean(details != null)}
        onClose={() => setDetails(null)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {details &&
          <PortionDetails
            split={details.split}
            portion={details.portion}
          />}
      </Popover>
      <StandardSearchGroup
        options={[
          { type: 'date', name: 'timeStart', label: '开始时间', },
          { type: 'date', name: 'timeEnd', label: '结束时间', },
          { type: 'text', name: 'platOrderId', label: '提款订单号' },
          { type: 'text', name: 'trxId', label: '存款订单号' },
          {
            type: 'component', render: () => {
              return (
                <MutiSelect
                  options={[
                    { label: '等待', value: 'WAITING' },
                    { label: '失败', value: 'FAILED' },
                    { label: '通过', value: 'PASS' },
                    { label: '成功', value: 'SUCCESS' },
                    { label: '撤单', value: 'RECALL' },
                  ]}
                  label={'状态'}
                  values={queryParams.statuses}
                  onChange={v => {
                    setQueryParams({
                      ...queryParams,
                      statuses: v,
                    })
                  }}
                />
              )
            }
          },
          {
            type: 'component', render: () => {
              return (
                <ToggleButton
                  sx={{ width: 110 }}
                  color="primary"
                  size="small"
                  value="check"
                  selected={Boolean(queryParams.uncompleted)}
                  onChange={() => {
                    setQueryParams({ ...queryParams, uncompleted: !queryParams.uncompleted })
                  }}
                >
                  {queryParams.uncompleted ? '未完成交易单' : '所有交易单'}
                </ToggleButton>
              )
            }
          },
          {
            type: 'component', width: 50, render: () => {
              return (
                <ToggleButton
                  sx={{ width: 110 }}
                  color="primary"
                  size="small"
                  value="check"
                  selected={Boolean(queryParams.onlyMine)}
                  onChange={() => {
                    setQueryParams({ ...queryParams, onlyMine: !queryParams.onlyMine })
                  }}
                >
                  {queryParams.onlyMine ? '我的交易单' : '全部交易单'}
                </ToggleButton>
              )
            }
          },
        ]}
        setVersion={setVersion}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }}
      />
      <StandardTable
        columns={[
          {
            headerName: '操作', field: 'nothing',
            renderCell: (params) =>
              <OpMenu
                id={params.row.id}
                pid={params.row.pid}
                trxId={params.row.trxId}
                status={params.row.status}
                afterOp={() => setVersion(v => v + 1)}
                alarm={params.row.alarmTime == null}
              />,
            width: 150,
          },
          {
            headerName: 'ID', field: 'id',
            renderCell: (params) => idWithDetailsButton(params.row),
          },
          {
            headerName: '提款订单号', field: 'platOrderId',
            renderCell: (params) => (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // 使子元素在水平方向上居中
                '& > *:not(:last-child)': { mb: -0.5 },
              }}>
                <CopyableText label={params.row.platOrderId} onClick={() => copied(params.row.platOrderId)} />
                <CopyableText label={params.row.orderId} onClick={() => copied(params.row.orderId)} />
              </Box >)
          },
          { headerName: '存款订单号', field: 'trxId', },
          {
            headerName: '耗时(分)', field: 'duration', width: 120,
            renderCell: (params) => (<TimeElapsed start={params.row.createTime} end={params.row.completeTime} />),
          },
          { headerName: '金额', field: 'amount', width: 90, },
          { headerName: '创建人', field: 'creater', },
          {
            headerName: '警报', field: 'alarmTime', width: 100,
            renderCell: (params) =>
              params.row.alarmTime
                ? (<TimeElapsed start={params.row.alarmTime} />)
                : '',
          },
          {
            headerName: '创建时间', field: 'createTime',
            renderCell: (params) => dayjs(params.row.createTime).format('YYYY-MM-DD HH:mm:ss')
          },
        ]}
        queryParams={queryParams}
        query={query}
        version={version}
        notFoundSrc={`no_items_found.png`}
        rowHeight={40}
      />
    </>
  )
}

function PortionDetails({ split, portion }) {
  const { setSnackbar } = useSnackbar();
  const copied = (text) => {
    return (
      <CopyableText
        style={{
          fontSize: 15,
          fontColor: 'green'
        }}
        color='green'
        label={text}
        copiedText={`${split.accountName}   ${split.cardId}   ${split.bankName}`}
        onClick={() => setSnackbar('已复制', 'success')}
      />
    );
  }
  return (
    <span>
      <Inspector title={'分单详情'} infos={[
        { label: '金额', value: copied(portion.amount) },
        { label: '商户', value: split.mchName },
        { label: '账户名', value: copied(split.accountName) },
        { label: '提款订单号', value: split.platOrderId },
        { label: '卡号', value: copied(split.cardId) },
        { label: '存款订单号', value: portion.trxId },
        { label: '银行', value: copied(split.bankName) },
        { label: '备注', value: portion.remark },
      ]} />
    </span>

  );
}